<template>
  <v-container class="my-5">
    <v-layout row wrap justify-space-between>
      <v-flex
        align-center
        xs10
        sm8
        md6
        class="mr-md-5 mr-xs-0"
        align-self-center
        offset-xs-1
        offset-sm-2
        offset-md-0
      >
        <v-img
          src="/img/auth/undraw_login.svg"
          class="ma-5"
          contain
          position="center center"
          aspect-ratio="16/9"
        ></v-img>
      </v-flex>
      <v-flex
        xs12
        sm8
        md5
        class="mb-5"
        align-self-center
        offset-sm-2
        offset-md-0
      >
        <v-card flat outlined>
          <v-card-title>
            <h2>Login</h2>
          </v-card-title>
          <v-card-text>
            <v-form class="px-3" ref="loginForm">
              <v-text-field
                label="Email"
                v-model="email"
                :rules="inputRules"
                type="email"
              >
              </v-text-field>
              <v-text-field
                label="Password"
                v-model="password"
                :rules="inputRules"
                type="password"
              >
              </v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mx-0 mt-3" @click="submitLoginForm"
                >Log In</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <div class="text-center mt-5">
      <v-btn
        outlined
        color="primary"
        block
        class="mt-3"
        x-large
        :to="`/auth/register`"
      >
        <span>Register</span>
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      inputRules: [(v) => (v && v.length > 0) || "This field is required"],

      loginURL: process.env.VUE_APP_LOGIN_URL,
    };
  },
  methods: {
    async submitLoginForm() {
      const email = this.$data.email;
      const password = this.$data.password;
      let request = await fetch(this.$data.loginURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      if (request.status === 200 || request.status === 401) {
        const text = await request.text();
        const data = await JSON.parse(text);

        //User ist eingeloggt -> weiterleiten an dashboard
        this.saveResponseDataToStore(data.user, data.token, data.expiresIn);
        this.$router.push("/dashboard");
      } else {
        //some other error
        const text = await request.text();
        const data = await JSON.parse(text);

        console.log(request);
        //TODO: create error response from the server msg
        console.log(data.msg);
        this.$router.push("/login");
      }
    },
    saveResponseDataToStore(user, token, expirationDate) {
      //saving user
      this.$store.dispatch("user/save_user", user);
      //saving token
      const saveToken = { token: token, expiresIn: expirationDate };
      this.$store.dispatch("user/save_token", saveToken);
      //changing auth status
      this.$store.dispatch("user/change_auth_status", true);
    },
  },
};
</script>

<style>
</style>